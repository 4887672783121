// eslint-disable-next-line import/no-cycle
import { DEFAULT_TABLE_PAGE_SIZE } from '@api/paging-utils';
import { getServiceClient } from '../components/auth/auth';
export async function getLicenseActivationLink() {
    const res = await getServiceClient().get('/api/license/activate');
    return res.data.activationUrl;
}
export async function postLicense(license) {
    const res = await getServiceClient().post('/api/license', {
        token: license
    });
    return res.data;
}
export async function readLicense() {
    const res = await getServiceClient().get('/api/license');
    return res.data;
}
export async function downloadReport() {
    const res = await getServiceClient().get('/api/usage-stats/report');
    return res.data;
}
export async function sendUsage() {
    await getServiceClient().post('/api/usage-stats/sending-status');
}
export async function synchronizeLicense() {
    const response = await getServiceClient().post('/api/license/sync');
    return response.data;
}
export async function licenseHistory(filters, limit = DEFAULT_TABLE_PAGE_SIZE) {
    const params = await makeLicenseEventsArgs(filters, limit);
    const res = await getServiceClient().get('/api/license/audit-logs', {
        params
    });
    return res.data;
}
async function makeLicenseEventsArgs(params, limit) {
    const { ...restParams } = params;
    return {
        limit: limit ? `${limit}` : undefined,
        ...restParams
    };
}
